// .noti-icon .badge{
//     left :23px;
// }
// .mt-2
// {
//  margin-top: 20px!important;
// }

// .dataTables_filter, .dataTables_paginate
// {
//     float: right;
// }

// .rdw-editor-main
// {
//     border: 1px solid #eeeef5;
//     height: 239px;
// }
// .dz-message
// {
//      text-align: center;
//     padding: 100px;
// }
// .fcYNFc
// {
//         background-color: #ffffff!important;
// }
// .task-box 
// {
//     border : 1px solid #e1e1e6;
// }
// .react-datepicker-wrapper
// {
//     width: 100%!important;
// }

// .ReactModal__Overlay
// {
//   z-index: 1001!important;
// }

// .fc-event .fc-content{
//     padding: 5px;
//     color: #FFF;
// }
// .chat-conversation .right .conversation-list{
// margin-right: 15px;
// }

// .external-event{
//     &:hover{
//         cursor: pointer;
//     }
// }

input[readonly] {
    background-color:transparent;
    border: 0;
}

.form-control:disabled {
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    color: #495057;
}

.form-select{
    padding-right: 20px;
}

.limit-text {
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    padding-bottom: 0 !important;
    max-width: 50rem;
}

.limit-text-2 {
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    white-space: nowrap;
    -webkit-box-orient: vertical;
    padding-bottom: 0 !important;
    max-width: 15rem;
}

.form-group textarea{
    height: 400px;
}

.image-parent {
    max-width: 40px;
  }
.copy-text{
    margin-left: 10px;
}
.button-click{
    cursor: pointer;
}

.txt-spinner{
    margin-left: 10px;
    margin-top: -4px;
}
.ctn-checkbox{
    display: flex;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 8px;
}
.checkbox-default {
    width: 16px;
    height: 16px;
    margin-right: 12px;
}
.label-checkbox{
    margin-bottom: 0px;
}
.error-text{
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #f46a6a;
    display: block;
}

.img-directory{
    width: 100px;
    height: 100px;
    object-fit: cover;
}
.ctn-btn-action{
    display: flex;
    justify-content: flex-end;
}
.pagination-wrapper ul{
    flex-wrap: wrap;
    justify-content: center;
}
.ctn-filter{
    position: 'relative';
    display: flex;
    align-items: center;
}
.filter-item{
    width: 180px;
    margin-right: 16px;
}
.title-mg{
    margin-bottom: 0px;
}
.loader-mg{
    margin-top: -4px;
}
.ctn-loader{
    justify-content: center;
    align-items: center;
    display: flex;
    padding-top: 16px;
}
.total-text{
    margin-top: 12px;
}