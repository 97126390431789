
@import "./icons.scss";
@import "./custom.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// RTL Mode
// @import "./bootstrap.scss";
// @import "./app-rtl.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

.background-noauth {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#sidebar-menu {
    ul {
        li {
            a {
                i {
                    min-width: 2rem;
                }
            }
        }
    }
}
.noti-icon .badge {
    left: 23px;
}
.dataTables_filter, .dataTables_paginate {
    float: right;
}
.rdw-editor-main {
    border: 1px solid $gray-300;
    height: 239px;
}
.dz-message {
    text-align: center;
    padding: 30px;
}
// kanban board
.fcYNFc, .sc-AxmLO.gmtmqV {
    background-color: $card-bg!important;
    margin: 0 20px 0 0 !important;
}
.task-box {
    border : 1px solid $border-color;
}
.react-datepicker-wrapper {
    width: 100%!important;
}
.ReactModal__Overlay {
    z-index: 1001!important;
}
.chat-conversation .right .conversation-list {
    margin-right: 15px;
}
.external-event {
    &:hover {
        cursor: pointer;
    }
}
a:hover {
    cursor: pointer;
}
.rating-container {
    background-color: transparent !important;
}
.input-group-append{
    z-index: 0;
}
.input-color{
    color: $input-color !important;
}
.sketch-picker {
    position: absolute;
    z-index: 1;
}
.rangeslider__fill{
    background-color: $primary !important;
}

@media only screen and (width: 1024px) {
    .wrapper-recaptcha {
      text-align: center;
      padding: '10px 0px';
      margin: '10px auto'; 
      text-align: 'center';
      transform:scale(0.79);
      -webkit-transform:scale(0.79);transform-origin:0 0;
      -webkit-transform-origin:0 0;
    } 
}

@media only screen and (width: 768px) {
    .wrapper-recaptcha {
      text-align: center;
      padding: '10px 0px';
      margin: '10px auto'; 
      text-align: 'center';
      transform:scale(0.92);
      -webkit-transform:scale(0.92);transform-origin:0 0;
      -webkit-transform-origin:0 0;
    } 
}

@media only screen and (min-width: 376px) and (max-width: 450px) {
    .wrapper-recaptcha {
      text-align: center;
      padding: '10px 0px';
      margin: '10px auto'; 
      text-align: 'center';
      transform:scale(1.10);
      -webkit-transform:scale(1.10);transform-origin:0 0;
      -webkit-transform-origin:0 0;
    } 
}

@media only screen and (width: 375px) {
  .wrapper-recaptcha {
    text-align: center;
    padding: '10px 0px';
    margin: '10px auto'; 
    text-align: 'center';
    transform:scale(0.97);
    -webkit-transform:scale(0.97);transform-origin:0 0;
    -webkit-transform-origin:0 0;
  } 
}


@media only screen and (width: 360px) {
    .wrapper-recaptcha {
      text-align: center;
      padding: '10px 0px';
      margin: '10px auto'; 
      text-align: 'center';
      transform:scale(0.92);
      -webkit-transform:scale(0.92);transform-origin:0 0;
      -webkit-transform-origin:0 0;
    } 
}

@media only screen and (width: 320px) {
    .wrapper-recaptcha {
      text-align: center;
      padding: '20px 0px';
      margin: '10px auto'; 
      text-align: 'center';
      transform:scale(0.78);
      -webkit-transform:scale(0.78);transform-origin:0 0;
      -webkit-transform-origin:0 0;
    } 
}